import React, { useEffect, useState } from "react"
import { useAuth } from "@auth/useAuth"
import SEO from "@components/seo"
import Layout from "@components/layout"
import { navigate } from "gatsby"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { toast } from "react-toastify"
import RadioBox from "../../../templates/EditSpace/fields/RadioSwitch"
import get from "lodash/get"

const ManageSpaceOperator = ({ location, ...props }) => {
  const [name, setName] = useState("")
  const [slug, setSlug] = useState("")
  const [saas, setSaas] = useState(false)
  const [progress, setProgress] = useState(false)
  const [space, setSpace] = useState({})

  const [legal_business_name, set_legal_business_name] = useState("")

  const [address, set_address] = useState("")

  const [domain, set_domain] = useState("")

  const [cancellation_deadline_in_hours, set_cancellation_deadline_in_hours] =
    useState("48")

  const [website_url, set_website_url] = useState("")

  const [monthly_offices_enabled, set_monthly_offices_enabled] = useState(false)

  const [on_demand_offices_daily_enabled, set_on_demand_offices_daily_enabled] =
    useState(false)

  const [
    on_demand_offices_hourly_enabled,
    set_on_demand_offices_hourly_enabled,
  ] = useState(false)

  const [day_passes_enabled, set_day_passes_enabled] = useState(false)

  const [dashboard_type, set_dashboard_type] = useState("")

  const { makeRequest } = useAuth() || {}

  useEffect(() => {
    const paramsString = get(location, "search") || ""
    let searchParams = new URLSearchParams(paramsString)
    if (searchParams.get("space_id")) {
      setSpace({ id: searchParams.get("space_id") })
    }
  }, [])

  const add = () => {
    if (!name || !slug || !legal_business_name || !address || !domain) {
      return
    }

    const soData = {
      name,
      slug,
      saas,
      legal_business_name,
      address,
      cancellation_deadline_in_hours,
      website_url,
      monthly_offices_enabled,
      on_demand_offices_daily_enabled,
      on_demand_offices_hourly_enabled,
      day_passes_enabled,
      dashboard_type,
      domain,
    }

    setProgress(true)
    makeRequest("create-space-operator", soData).then((res) => {
      if (res.sp && res.sp.id) {
        // success. Display the message and go to space-providers
        toast.success("Space operator was added successfully.")
        navigate("/admin/space-operators")
      } else {
        toast.error("Failed: " + res?.sp?.detail)
      }
      setProgress(false)
    })
  }

  if (space.id) {
    return (
      <>
        <SEO title="View Space Operator" />
        <Layout title={"View SO"} permission={"create:space-providers"}>
          <p>ID: {space.id}</p>
          <p>Slug: {space.slug}</p>
        </Layout>
      </>
    )
  }

  return (
    <>
      <SEO title="Add new Space Operator" />
      <Layout
        title={space.id ? "Edit Space Operator" : "Add New Space Operator"}
        permission={"create:space-providers"}
      >
        <Row>
          <Col lg="6">
            <h4>Details</h4>
            <hr />
            <FormGroup>
              <Label>Name *</Label>
              <Input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label>Slug *</Label>
              <Input
                type="text"
                placeholder="Slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
              />
              <small>
                Please use only letters and underscores. The slug should be
                unique for the space operator.
              </small>
            </FormGroup>

            <FormGroup>
              <Label>Legal Business Name *</Label>
              <Input
                type="text"
                placeholder="Legal Business Name"
                value={legal_business_name}
                onChange={(e) => set_legal_business_name(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label>Address *</Label>
              <Input
                type="text"
                placeholder="Address"
                value={address}
                onChange={(e) => set_address(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label>Domain *</Label>
              <Input
                type="text"
                placeholder="sodomain.com"
                value={domain}
                onChange={(e) => set_domain(e.target.value)}
              />
              <small>This is the main domain. i.e. worksimply.com</small>
            </FormGroup>

            <FormGroup>
              <Label>Website URL</Label>
              <Input
                type="text"
                placeholder="Website URL"
                value={website_url}
                onChange={(e) => set_website_url(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label>
                <input
                  type="checkbox"
                  checked={saas}
                  onChange={(e) => setSaas(e.target.checked)}
                />{" "}
                SAAS Customer (This field is used only for analytics.)
              </Label>
            </FormGroup>

            {!space.id && (
              <FormGroup>
                <Button
                  color={"success"}
                  onClick={add}
                  disabled={
                    !name ||
                    !slug ||
                    progress ||
                    !legal_business_name ||
                    !address ||
                    !domain
                  }
                >
                  {space.id ? "Update" : "Add"}
                </Button>
              </FormGroup>
            )}
          </Col>
          <Col lg="6">
            <h4>Configuration</h4>
            <hr />
            <RadioBox
              label="Monthly Workspace"
              onChange={(value) => {
                set_monthly_offices_enabled(value)
              }}
              name="coffee"
              value={monthly_offices_enabled}
              disabled={progress}
            />
            <hr />
            <RadioBox
              label="On-demand Offices - Daily"
              onChange={(value) => {
                set_on_demand_offices_daily_enabled(value)
              }}
              name="coffee"
              value={on_demand_offices_daily_enabled}
              disabled={progress}
            />
            <RadioBox
              label="On-demand Offices - Hourly"
              onChange={(value) => {
                set_on_demand_offices_hourly_enabled(value)
              }}
              name="coffee"
              value={on_demand_offices_hourly_enabled}
              disabled={progress}
            />
            <RadioBox
              label="Day Passes"
              onChange={(value) => {
                set_day_passes_enabled(value)
              }}
              name="coffee"
              value={day_passes_enabled}
              disabled={progress}
            />

            <hr />

            <FormGroup>
              <Label>Dashboard Type</Label>
              <Input
                type="select"
                value={dashboard_type}
                onChange={(e) => set_dashboard_type(e.target.value)}
              >
                <option value="">None</option>
                <option value="prospect_analysis">Prospect Analysis</option>
                <option value="inventory_analytics">Inventory Analytics</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label>Cancellation Deadline for On-demand Workspace</Label>
              <Input
                type="select"
                label={""}
                value={cancellation_deadline_in_hours}
                onChange={(e) =>
                  set_cancellation_deadline_in_hours(e.target.value)
                }
              >
                <option value="">None</option>
                <option value="24">24 Hours</option>
                <option value="48">48 Hours</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </Layout>
    </>
  )
}
export default ManageSpaceOperator
