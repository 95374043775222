import React from "react"
import { Col, Label, Row } from "reactstrap"
import RadioButton from "./_Radio_Button"

const RadioBox = ({
  label,
  name,
  value = false,
  onChange,
  disabled = false,
}) => {
  return (
    <Row className="mb-2">
      {label && (
        <Col xs="6" sm={"7"} className={"d-flex align-items-center"}>
          <Label for="" className={"my-0"}>
            {label}
          </Label>
        </Col>
      )}
      <Col
        xs={label ? "6" : "12"}
        sm={label ? "5" : "12"}
        className={label ? "text-right" : "text-center"}
      >
        <RadioButton
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </Col>
    </Row>
  )
}

export default RadioBox
