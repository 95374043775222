import React from "react"
import "./RadioSwitch.less"

const RadioButton = ({ name, value = false, onChange, disabled = false }) => {
  return (
    <div
      className={`custom-radio ${value && "check"} ${
        disabled ? "disabled" : ""
      }`}
      onClick={() => {
        if (!disabled) {
          onChange(!value)
        }
      }}
      role={"presentation"}
      tabIndex={0}
    >
      <input
        type="checkbox"
        name={name}
        checked={value}
        onChange={() => null}
        disabled={disabled}
      />
      <div className="dot" />
    </div>
  )
}

export default RadioButton
